import React, { useEffect, useRef, useState } from "react";
import {
  FilesetResolver,
  HandLandmarker,
  PoseLandmarker,
} from "@mediapipe/tasks-vision";
import {
  INITIALIZATION_CONSTRAINSTS,
  CONFIG,
  COLORS,
  ELLIPSE_PROPERTIES, 
  VIDEO_CONSTRAINTS,
  MEDIAPIPE_LANDMARKS,
  cameraTilt,
} from "./config";
import './App.css';
// //Import the model 
// import hand_landmarker_task from "./models/hand_landmarker.task";
// import pose_landmarker_task from "./models/pose_landmarker_lite.task";

const hand_landmarker_task = process.env.REACT_APP_HAND_LANDMARKER_TASK_PATH;
const pose_landmarker_task = process.env.REACT_APP_POSE_LANDMARKER_TASK_PATH;


const App = () => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [isStreaming, setIsStreaming] = useState(false);
  const [isPerfectVisibility, setPerfectVisibility] = useState(false);
  const [warningMessage, setMessage] = useState("");
  const [tiltAngle, setTiltAngle] = useState(null); // State to store tilt angle
  let handLandmarker, poseLandmarker;
  let animationFrameId;
  let lastPoseTimestamp = 0;
  let lastHandTimestamp = 0;
  

  useEffect(() => {
    return () => stopStream(); // Cleanup on component unmount
  }, []);

  const initializeHandDetectionAndPose = async () => {
    try {
      const vision = await FilesetResolver.forVisionTasks(CONFIG.WASM_URL);
      console.log("Hand Landmarker Task Path:", hand_landmarker_task);
      console.log("Pose Landmarker Task Path:", pose_landmarker_task);

      handLandmarker = await HandLandmarker.createFromOptions(vision, {
        baseOptions: { 
          modelAssetPath: hand_landmarker_task, 
          delegate: INITIALIZATION_CONSTRAINSTS.DELEGATE,
         },
        runningMode: INITIALIZATION_CONSTRAINSTS.RUNNING_MODE,
        numHands: INITIALIZATION_CONSTRAINSTS.NUM_HANDS,
      });

      poseLandmarker = await PoseLandmarker.createFromOptions(vision, {
        baseOptions: { 
          modelAssetPath: pose_landmarker_task, 
          delegate: INITIALIZATION_CONSTRAINSTS.DELEGATE,
        },
        runningMode: INITIALIZATION_CONSTRAINSTS.RUNNING_MODE,
        numPoses: INITIALIZATION_CONSTRAINSTS.NUM_POSES,
      });

      detectHandsAndPose();
    } catch (error) {
      console.error("Error initializing hand detection:", error);
    }
  };

/**
 * Detects the tilt angle of the camera based on the provided image data.
 * @param {ImageData} imageData - The image data from the video frame.
 * @returns {number|null} The tilt angle in degrees, or null if no edges are detected.
 */
  const detectTilt = (imageData) => {
    const { data, width, height } = imageData;
    let topEdgeSum = 0;
    let bottomEdgeSum = 0;
    let edgeCount = 0;

    // Simple horizontal line detection
    for (let y = 0; y < height; y++) {
      let topEdge = 0;
      let bottomEdge = 0;

      for (let x = 0; x < width; x++) {
        const index = (y * width + x) * 4;
        const isEdge = data[index] + data[index + 1] + data[index + 2] < 50; // Simple thresholding

        if (isEdge) {
          edgeCount++;
          if (y < height / 2) {
            topEdge++;
          } else {
            bottomEdge++;
          }
        }
      }

      topEdgeSum += topEdge;
      bottomEdgeSum += bottomEdge;
    }

    if (edgeCount === 0) {
      return null;
    }

    const totalTopEdge = topEdgeSum / height;
    const totalBottomEdge = bottomEdgeSum / height;
    const tilt = (totalBottomEdge - totalTopEdge) / (width / 2);

    // Convert tilt to degrees
    const tiltDegrees = Math.atan(tilt) * (180 / Math.PI);

    return tiltDegrees;
  };

  const analyzeTilt = () => {
    if (videoRef.current) {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const video = videoRef.current;
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

      //Detect and set the tilt angle
      const tilt = detectTilt(imageData);
      if (tilt !== null) {
        setTiltAngle(Math.abs(tilt));
      }
    }
  };


  // Check the visibility of key body landmarks and return appropriate warnings
const checkVisibility = (poseLandmarks) => {
  try {
    if (!poseLandmarks || poseLandmarks.length === 0) {
      return { status: "warning", message: CONFIG.WARNING_MESSAGES.UPPER_BODY_NOT_VISIBLE };
    }

    const warnings = poseLandmarks.map((landmarks) => {
      const rightEyeVisibility = Math.floor(landmarks[MEDIAPIPE_LANDMARKS.RIGHT_EYE].visibility * 100);
      const leftEyeVisibility = Math.floor(landmarks[MEDIAPIPE_LANDMARKS.LEFT_EYE].visibility * 100);
      const rightHipVisibility = Math.floor(landmarks[MEDIAPIPE_LANDMARKS.RIGHT_HIP].visibility * 100);
      const leftHipVisibility = Math.floor(landmarks[MEDIAPIPE_LANDMARKS.LEFT_HIP].visibility * 100);

      const upperBodyVisible = rightEyeVisibility >= CONFIG.VISIBILITY_THRESHOLD || leftEyeVisibility >= CONFIG.VISIBILITY_THRESHOLD;
      const lowerBodyVisible = rightHipVisibility >= CONFIG.VISIBILITY_THRESHOLD || leftHipVisibility >= CONFIG.VISIBILITY_THRESHOLD;

      if (!upperBodyVisible && !lowerBodyVisible) {
        return { status: "warning", message: CONFIG.WARNING_MESSAGES.BODY_NOT_VISIBLE };
      } else if (!upperBodyVisible) {
        return { status: "warning", message: CONFIG.WARNING_MESSAGES.UPPER_BODY_NOT_VISIBLE };
      } else if (!lowerBodyVisible) {
        return { status: "warning", message: CONFIG.WARNING_MESSAGES.LOWER_BODY_NOT_VISIBLE };
      } else {
        return { status: "Perfect!", message: CONFIG.WARNING_MESSAGES.PERFECT };
      }
    });

    return warnings[0] || { status: "warning", message: CONFIG.WARNING_MESSAGES.UNEXPECTED_CASE };

  } catch (error) {
    console.error(`Error while checking visibility: ${error.message}`, error);
    return { status: "error", message: CONFIG.WARNING_MESSAGES.ERROR_OCCURED };
  }
};


  // Helper function to calculate shoulder positions and visibility
const calculateShoulderMetrics = (landmarks, canvasWidth, canvasHeight) => {
  const rightShoulderX = landmarks[MEDIAPIPE_LANDMARKS.RIGHT_SHOULDER].x * canvasWidth;
  const rightShoulderY = landmarks[MEDIAPIPE_LANDMARKS.RIGHT_SHOULDER].y * canvasHeight;
  const leftShoulderX = landmarks[MEDIAPIPE_LANDMARKS.LEFT_SHOULDER].x * canvasWidth;
  const leftShoulderY = landmarks[MEDIAPIPE_LANDMARKS.LEFT_SHOULDER].y * canvasHeight;
  const rightShoulderVisibility = landmarks[MEDIAPIPE_LANDMARKS.RIGHT_SHOULDER].z * canvasHeight;
  const leftShoulderVisibility = landmarks[MEDIAPIPE_LANDMARKS.LEFT_SHOULDER].z * canvasHeight;

  const shoulderOffset = calculateDistance([rightShoulderX, rightShoulderY], [leftShoulderX, leftShoulderY]);

  return { shoulderOffset, rightShoulderVisibility, leftShoulderVisibility };
};

// Function to check posture based on landmarks
const checkPosture = (poseLandmarks) => {
  if (!poseLandmarks || poseLandmarks.length === 0) {
    return { status: "error", message: CONFIG.WARNING_MESSAGES.NO_LANDMARKS_FOUND };
  }

  const canvasWidth = canvasRef.current.width;
  const canvasHeight = canvasRef.current.height;

  const { shoulderOffset, rightShoulderVisibility, leftShoulderVisibility } = calculateShoulderMetrics(
    poseLandmarks[0],
    canvasWidth,
    canvasHeight
  );

  if (shoulderOffset < ELLIPSE_PROPERTIES.postureOffsetThreshold) {
    if (rightShoulderVisibility < leftShoulderVisibility) {
      return { status: "warning", message: CONFIG.WARNING_MESSAGES.SIDE_POSTURE_LEFT };
    } else {
      return { status: "warning", message: CONFIG.WARNING_MESSAGES.SIDE_POSTURE_RIGHT };
    }
  }

  return { status: "warning", message: CONFIG.WARNING_MESSAGES.FRONT_POSTURE };
};

  const calculateDistance = ([x1, y1], [x2, y2]) => Math.hypot(x2 - x1, y2 - y1);

  /**
 * Determines if a point is inside an ellipse.
 * 
 * @param {number} pointX - The x-coordinate of the point to check.
 * @param {number} pointY - The y-coordinate of the point to check.
 * @param {number} ellipseCenterX - The x-coordinate of the ellipse center.
 * @param {number} ellipseCenterY - The y-coordinate of the ellipse center.
 * @param {number} radiusX - The horizontal radius of the ellipse.
 * @param {number} radiusY - The vertical radius of the ellipse.
 * @param {number} rotation - The rotation of the ellipse in radians.
 * @returns {boolean} - True if the point is inside the ellipse, false otherwise.
 */

const isPointInEllipse = (
  pointX,
  pointY,
  ellipseCenterX,
  ellipseCenterY,
  radiusX,
  radiusY,
  rotation
) => {
  // Calculate the cosine and sine of the rotation angle
  const cosRotation = Math.cos(rotation);
  const sinRotation = Math.sin(rotation);

  // Calculate the distance of the point from the center of the ellipse
  const deltaX = pointX - ellipseCenterX;
  const deltaY = pointY - ellipseCenterY;

  // Apply rotation and scaling to the point
  const transformedX = cosRotation * deltaX + sinRotation * deltaY;
  const transformedY = sinRotation * deltaX - cosRotation * deltaY;

  // Check if the transformed point lies within the ellipse
  const normalizedX = (transformedX ** 2) / (radiusX ** 2);
  const normalizedY = (transformedY ** 2) / (radiusY ** 2);
  const ellipseEquation = normalizedX + normalizedY;

  return ellipseEquation <= 1;
};

 // Main function to draw landmarks based on posture
const drawLandmarks = (poseLandmarks, handLandmarks, postureType) => {
  const canvas = canvasRef.current;
  const ctx = canvas?.getContext("2d");

  if (!canvas || !ctx || !videoRef.current) {
      console.error("Canvas or video reference is not available.");
      return;
  }

  setupCanvasContext(ctx, canvas);
  const ellipses = calculateEllipses(poseLandmarks, canvas, postureType);
  drawEllipsesWithHandCheck(ctx, ellipses, handLandmarks, canvas);
  ctx.restore();
};

// Set up the canvas context
const setupCanvasContext = (ctx, canvas) => {
  ctx.save();
  ctx.scale(-1, 1);
  ctx.translate(-canvas.width, 0);
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
};

// Calculate ellipses based on the posture type
const calculateEllipses = (poseLandmarks, canvas, postureType) => {
  return poseLandmarks.flatMap((landmarks) => {
      let hipX, hipY, shoulderX, shoulderY,rotation;

      if (postureType === CONFIG.WARNING_MESSAGES.LEFT_POSTURE) {
          hipX = (landmarks[MEDIAPIPE_LANDMARKS.LEFT_HIP].x * canvas.width)- ELLIPSE_PROPERTIES.correctionValueSidePosture;
          hipY = landmarks[MEDIAPIPE_LANDMARKS.LEFT_HIP].y * canvas.height;
          shoulderX = landmarks[MEDIAPIPE_LANDMARKS.LEFT_SHOULDER].x * canvas.width;
          shoulderY = landmarks[MEDIAPIPE_LANDMARKS.LEFT_SHOULDER].y * canvas.height;
          rotation=1;
      } else if (postureType === CONFIG.WARNING_MESSAGES.RIGHT_POSTURE) {
          hipX = (landmarks[MEDIAPIPE_LANDMARKS.RIGHT_HIP].x * canvas.width)+ELLIPSE_PROPERTIES.correctionValueSidePosture;
          hipY = landmarks[MEDIAPIPE_LANDMARKS.RIGHT_HIP].y * canvas.height;
          shoulderX = landmarks[MEDIAPIPE_LANDMARKS.RIGHT_SHOULDER].x * canvas.width;
          shoulderY = landmarks[MEDIAPIPE_LANDMARKS.RIGHT_SHOULDER].y * canvas.height; 
          rotation=-1;
      } else {
          // Front posture
          const rightHipX = landmarks[MEDIAPIPE_LANDMARKS.RIGHT_HIP].x * canvas.width;
          const rightHipY = landmarks[MEDIAPIPE_LANDMARKS.RIGHT_HIP].y * canvas.height;
          const leftHipX = landmarks[MEDIAPIPE_LANDMARKS.LEFT_HIP].x * canvas.width;
          const leftHipY = landmarks[MEDIAPIPE_LANDMARKS.LEFT_HIP].y * canvas.height;
          const rightShoulderX = landmarks[MEDIAPIPE_LANDMARKS.RIGHT_SHOULDER].x * canvas.width;
          const rightShoulderY = landmarks[MEDIAPIPE_LANDMARKS.RIGHT_SHOULDER].y * canvas.height;
          const distanceBetweenHips = Math.floor(
              calculateDistance(
                [leftHipX, leftHipY],
                [rightHipX, rightHipY]
              )
          );
          const distanceBetweenHipAndShoulder = Math.floor(
              calculateDistance(
                [leftHipX, leftHipY], 
                [rightShoulderX, rightShoulderY]
              )
          );
          return [
              createEllipseConfig(
                rightHipX - CONFIG.CORRECTION_VALUES.X_COORDINATE, 
                rightHipY - CONFIG.CORRECTION_VALUES.Y_COORDINATE, 
                distanceBetweenHips, distanceBetweenHipAndShoulder, 
                ELLIPSE_PROPERTIES.rightFrontRotationOffset
              ),
              createEllipseConfig(
                leftHipX + CONFIG.CORRECTION_VALUES.X_COORDINATE, 
                leftHipY - CONFIG.CORRECTION_VALUES.Y_COORDINATE, 
                distanceBetweenHips, distanceBetweenHipAndShoulder, 
                ELLIPSE_PROPERTIES.leftFrontRotationOffset
              )
          ];
      }

      const distanceBetweenHips = CONFIG.DISTANCE_BETWEEN_HIPS;
      const distanceBetweenHipAndShoulder = Math.floor(
          calculateDistance(
            [hipX, hipY], 
            [shoulderX, shoulderY]
          )
      );

      return [
        createEllipseConfig(
          hipX, 
          hipY, 
          distanceBetweenHips, 
          distanceBetweenHipAndShoulder, 
          ELLIPSE_PROPERTIES.rotationOffset*rotation
        )
      ];
  });
};

// Create an ellipse configuration object
const createEllipseConfig = (x, y, distanceBetweenHips, distanceBetweenHipAndShoulder, rotationOffset) => {
  const minorAxis = Math.floor(distanceBetweenHips / CONFIG.MINOR_AXIS_DIVIDER);
  const majorAxis = Math.floor(distanceBetweenHipAndShoulder / CONFIG.MAJOR_AXIS_DIVIDER);
  return {
      x: x,
      y: y,
      majorAxis,
      minorAxis,
      rotation: (rotationOffset * Math.PI) / 180
  };
};

// Draw ellipses on the canvas and check if hands are inside
const drawEllipsesWithHandCheck = (ctx, ellipses, handLandmarks, canvas) => {
  ellipses.forEach((ellipse) => {
      const isHandInside = handLandmarks.some((landmarks) =>
          landmarks.some((landmark) =>
              isPointInEllipse(
                  landmark.x * canvas.width,
                  landmark.y * canvas.height,
                  ellipse.x,
                  ellipse.y,
                  ellipse.majorAxis,
                  ellipse.minorAxis,
                  ellipse.rotation
              )
          )
      );

      ctx.fillStyle = isHandInside ? COLORS.HAND_INSIDE : COLORS.HAND_OUTSIDE;
      ctx.beginPath();
      ctx.ellipse(
          ellipse.x,
          ellipse.y,
          ellipse.majorAxis,
          ellipse.minorAxis,
          ellipse.rotation,
          0,
          2 * Math.PI
      );
      ctx.fill();
  });
};

// Main function to detect hands and pose
const detectHandsAndPose = () => {
  const currentTimestamp = performance.now();

  if (videoRef.current && videoRef.current.readyState >= 2) {
      const poseDetections = shouldDetectPose(currentTimestamp) ? detectPose(currentTimestamp) : null;
      const handDetections = shouldDetectHands(currentTimestamp) ? detectHands(currentTimestamp) : null;

      if (poseDetections && handDetections) {
          handleDetections(poseDetections.landmarks, handDetections.landmarks);
      }
  }

  animationFrameId = requestAnimationFrame(detectHandsAndPose);
};

// Function to check if pose should be detected based on timestamp
const shouldDetectPose = (currentTimestamp) => {
  return currentTimestamp - lastPoseTimestamp > CONFIG.FRAME_INTERVAL;
};

// Function to check if hands should be detected based on timestamp
const shouldDetectHands = (currentTimestamp) => {
  return currentTimestamp - lastHandTimestamp > CONFIG.FRAME_INTERVAL;
};

// Function to detect pose
const detectPose = (currentTimestamp) => {
  lastPoseTimestamp = currentTimestamp;
  return poseLandmarker.detectForVideo(videoRef.current, currentTimestamp);
};

// Function to detect hands
const detectHands = (currentTimestamp) => {
  lastHandTimestamp = currentTimestamp;
  return handLandmarker.detectForVideo(videoRef.current, currentTimestamp);
};

// Handle pose and hand detections
const handleDetections = (poseLandmarks, handLandmarks) => {
  const visibilityMessage = checkVisibility(poseLandmarks);
  setMessage(visibilityMessage.message);

  const postureMessage = checkPosture(poseLandmarks).message;
  const isPerfectVisibility = visibilityMessage.message === CONFIG.WARNING_MESSAGES.PERFECT;
  setPerfectVisibility(isPerfectVisibility)
  if (isPerfectVisibility) {
    analyzeTilt()
      if (postureMessage === CONFIG.WARNING_MESSAGES.FRONT_POSTURE) {
          drawLandmarks(poseLandmarks, handLandmarks, CONFIG.WARNING_MESSAGES.FRONT);
      } else if (postureMessage === CONFIG.WARNING_MESSAGES.SIDE_POSTURE_RIGHT) {
          drawLandmarks(poseLandmarks, handLandmarks, CONFIG.WARNING_MESSAGES.RIGHT_POSTURE);
      } else if (postureMessage === CONFIG.WARNING_MESSAGES.SIDE_POSTURE_LEFT) {
          drawLandmarks(poseLandmarks, handLandmarks, CONFIG.WARNING_MESSAGES.LEFT_POSTURE);
      } else {
          clearCanvas();
      }
  } else {
      clearCanvas();
  }
};

// Start the webcam and initialize detection
const startWebcam = async () => {
  try {
      const stream = await navigator.mediaDevices.getUserMedia(VIDEO_CONSTRAINTS);
      videoRef.current.srcObject = stream;
      setIsStreaming(true);
      await initializeHandDetectionAndPose();
  } catch (error) {
      console.error("Error accessing webcam:", error);
  }
};

// Stop the webcam stream and cleanup resources
const stopStream = () => {
  if (videoRef.current && videoRef.current.srcObject) {
      videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
  }
  if (handLandmarker) {
      handLandmarker.close();
  }
  if (animationFrameId) {
      cancelAnimationFrame(animationFrameId);
  }
  setIsStreaming(false);
};

// Clear the canvas
const clearCanvas = () => {
  const canvas = canvasRef.current;
  const ctx = canvas?.getContext("2d");
  if (!canvas || !ctx || !videoRef.current) {
      console.error("Canvas or video reference is not available.");
      return;
  }
  ctx.save();
  ctx.scale(-1, 1);
  ctx.translate(-canvas.width, 0);
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
  ctx.restore();
};

return (
  <div className="container">
    <div className="video-container">
      <video
        ref={videoRef}
        autoPlay
        playsInline
        className={'video-hidden'}
      ></video>
      {!isStreaming && <span className="feedback-hidden">Camera is off</span>}
    </div>
    <div className="canvas-container">
      <canvas
        ref={canvasRef}
        width="360px"
        height="360px"
        className={isStreaming ? "canvas-visible" : "video-hidden"}
      ></canvas>
      {!isStreaming && <span className="feedback-hidden">Canvas is off</span>}
    </div>

    <div className="button-container">
      <button
        onClick={startWebcam}
        disabled={isStreaming}
        className="button start-button"
      >
        Start Stream
      </button>
      <button
        onClick={stopStream}
        disabled={!isStreaming}
        className="button stop-button"
      >
        Stop Stream
      </button>
    </div>

    {isStreaming && (
  <div className="feedback">
    <p>Feedback: {warningMessage}</p>
    <p>
      {tiltAngle !== null
        ? (tiltAngle >= cameraTilt.minValue && tiltAngle <= cameraTilt.maxValue && isPerfectVisibility
          ? cameraTilt.perfectCalibrationMessage
          : cameraTilt.cameraAdjustMessage)
        : cameraTilt.calibratingCameraMeesage}
    </p>
  </div>
)}
  </div>
);
};

export default App;
