export const CONFIG = {
    WASM_URL: "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@latest/wasm",
    VIDEO_CONSTRAINTS: true,
    HAND_LANDMARKER_TASK_PATH: process.env.REACT_APP_HAND_LANDMARKER_TASK_PATH,  // Hand Landmarker Task Path
    POSE_LANDMARKER_TASK_PATH: process.env.REACT_APP_POSE_LANDMARKER_TASK_PATH, // Pose Landmarker Task Path 
    VISIBILITY_THRESHOLD: 85,     // This is a threshold for visibility of certain landmark points to determine the posture {Front, Side Right, Side Left}
    MINOR_AXIS_DIVIDER: 8,        // This divider is used to dynamically calculate the minor axis value used to draw ellipses according to the users waist
    MAJOR_AXIS_DIVIDER: 8,        // This divider values is used to dynamically calculate the major axis value to draw ellipses according to the shoulder and hip ratio.
    DISTANCE_BETWEEN_HIPS: 60,    
    FRAME_INTERVAL: 100, // Interval for checking poses and hands in milliseconds
    // Feedback Messages
    WARNING_MESSAGES: {
      UPPER_BODY_NOT_VISIBLE: "Your Upper Body is not visible.",  
      LOWER_BODY_NOT_VISIBLE: "Your Lower Body is not visible.",
      BODY_NOT_VISIBLE: "Your Body is not visible.",
      PERFECT: "Perfect!",
      FRONT_POSTURE:"Front Posture",
      SIDE_POSTURE_LEFT: "Side Posture Left",
      SIDE_POSTURE_RIGHT: "Side Posture Right",
      FORNT_POSTURE: "Front Posture",
      ERROR_OCCURED: "Error occurred.",
      UNEXPECTED_CASE: "Unexpected case encountered.",
      LEFT_POSTURE: "left",
      RIGHT_POSTURE: "right",
      FRONT: "front",
    },
    // These values are determined through iterative adjustments to accurately position the ellipse and define the release zone.
    CORRECTION_VALUES:{
        X_COORDINATE:6,
        Y_COORDINATE: 10,

    }
  };
// Values to change the properties of ellipses 
  export const ELLIPSE_PROPERTIES = {
    rotationOffset: 108,
    correctionValueSidePosture:20,
    rightFrontRotationOffset:-110,
    leftFrontRotationOffset:110,
    minorAxisFactor: 8,
    majorAxisFactor: 8,
    visibilityThreshold: 85,
    postureOffsetThreshold: 50,
    defaultScaleFactor: -1,
  };

  export const VIDEO_CONSTRAINTS = {
    video: true,
  };

  // Initialization Constraints of Mediapipe
  export const INITIALIZATION_CONSTRAINSTS = {
    RUNNING_MODE: 'video',
    DELEGATE: 'GPU',
    NUM_HANDS: 2,
    NUM_POSES: 1,
  };

// Used Mediapipe Landmarks
export const MEDIAPIPE_LANDMARKS={
    RIGHT_EYE: 5,
    LEFT_EYE: 2,
    RIGHT_HIP: 24,
    LEFT_HIP: 23,
    RIGHT_SHOULDER:11,
    LEFT_SHOULDER:12,
};

// Colours used in Ellipses
export const COLORS={
    HAND_INSIDE: "rgb(0,255,0)",
    HAND_OUTSIDE: "blue",
};
// Camera Tilt Properties
export const cameraTilt={
  minValue: 0,
  maxValue:10,
  perfectCalibrationMessage: "The camera is perfectly calibrated.",
  cameraAdjustMessage: "Please adjust the camera to ensure it is straight.",
  calibratingCameraMeesage: "Calibrating camera angle...",
}